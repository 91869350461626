import "../src/shared/wrap_ready.js";
import Cookie from "../src/shared/cookie";
// import Header from "../src/livractive/components/header";

window.Livractive = {};

window.addEventListener("DOMContentLoaded", () => {
  window.Livractive.Cookie = new Cookie();

  // window.Livractive.Header = new Header({ selector: ".nav-bar" });
  // window.Livractive.Header.start();
});

// we moved Bootstrap here afer updating to BS5 because
// it wasn't loaded in the right order in application.js
import * as bootstrap from '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

window.bootstrap = bootstrap;

import "../../whitelabels/livractive/assets/javascripts/application.js";
